import React from "react";
// import specific_location_img from "../../images/hyderabad.jpg";
import { Link } from "react-router-dom";
import ChatButton from "../../components/ChatButton";

const Noida = () => {
  return (
    <>

      <div className="content_page_wrapper">
        <div className="content_page_banner_wrapper noida_banner">
          <div className="content_page_banner_wrapper_overlay">
            <h2>Location - Noida</h2>
          </div>
        </div>

        <div className="text_content_wrapper">
          <div className="text_content">
            {/* <div className="content_banner">
                <img src={specific_location_img} alt="" />
              </div> */}
            {/* <h1 className="page_title mt30 mb20">Get an Instant Personal Loan in Noida in No Time</h1> */}
            <h1 className="page_title mt30 mb20">Experience Financial Ease with Surya Loan's Instant Personal Loans in Noida</h1>

            <h2>Introduction: Your Financial Companion in Noida</h2>
            <p>In the dynamic city of Noida, financial stability and flexibility are crucial. Surya Loan understands this need and offers instant personal loans designed to meet your diverse financial requirements. Whether it's for unexpected medical expenses, home improvements, or a much-needed vacation, Surya Loan is here to help.</p>

            <h2>Why Surya Loan Stands Out for Personal Loans in Noida</h2>

            <h3>Swift Approval for Instant Personal Loans</h3>
            <p>One of the key features that set Surya Loan apart is the swift approval process. In today's fast-paced environment, time is invaluable. Surya Loan ensures that your loan application is processed quickly, often within just 24 hours, making it one of the most reliable choices for instant personal loans in Noida.</p>

            <h3>Flexible Repayment Plans</h3>
            <p>Surya Loan offers flexible repayment plans tailored to your financial situation. Choose a repayment tenure that fits your budget, ensuring manageable monthly payments without financial strain.</p>

            <h2>Benefits of Choosing Surya Loan in Noida</h2>

            <h3>Transparent, No Hidden Charges</h3>
            <p>Transparency is a cornerstone at Surya Loan. There are no hidden charges, so you know exactly what you're paying for. The loan agreement is clear, with all terms and conditions explicitly stated.</p>

            <h3>Dedicated Customer Support</h3>
            <p>Surya Loan prides itself on its dedicated customer support team. Whether you have a question about your loan application or need assistance with repayment, the customer support team is always ready to help.</p>

            <h2>Conclusion: Your Trusted Financial Partner in Noida</h2>
            <p>When it comes to instant personal loans in Noida, Surya Loan stands out as a reliable and efficient choice. With quick approvals, competitive interest rates, and a customer-centric approach, Surya Loan is committed to helping you achieve your financial dreams.</p>

            <p><strong>Don't let financial constraints hold you back.</strong> Apply for an instant personal loan with Surya Loan today and experience financial freedom.</p>

            <div className="button_container flex justify-center mt30">
              <Link to="/apply-now">Apply Now</Link>
            </div>
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};

export default Noida;
