import React from "react";
import "../css/ContentPage.css";
import short_loan from "../images/short_loan.jpg";
import { Link } from "react-router-dom";
import ChatButton from "../components/ChatButton";

const ShortLoan = () => {
  return (
    <>

      <div className="content_page_wrapper">
        <div className="content_page_banner_wrapper">
          <div className="service_page_banner_wrapper_overlay">
            <h2>Short Term Loans</h2>
          </div>

          <div className="text_content_wrapper">
            <div className="text_content">
              <div className="content_banner">
                <img src={short_loan} alt="" />
              </div>
              <h1 className="page_title mt30 mb20">SHORT PERSONAL LOAN</h1>
              <p>In life, emergencies and unexpected expenses like medical expenses, an urgent car repair, or an instant home repair can come at any time, many times leaving us with limited options to meet quick financial requirements. Surya Loan's short personal loans might be the solution for all your financial needs without lengthy application processes.</p>

              <h2>What is a Short Loan?</h2>
              <p>A short-term loan is designed to provide quick financial help for your quick financial needs. These loans normally have shorter repayment terms compared to ancient loans, often covering from a few weeks to a year.</p>

              <h2>Why Choose Short Loan?</h2>
              <ul>
                <li><strong>Fast Access to Funds:</strong> Short loan approval is quick, ensuring money is received within a few hours to a few days, crucial during emergencies.</li>
                <li><strong>Easily Apply:</strong> The application process is simple with minimal paperwork and no collateral.</li>
                <li><strong>Short-Term Commitment:</strong> Reduces financial burden as repayment is short-term, avoiding long-term debt concerns.</li>
              </ul>

              <h2>Benefits of Taking Short Loans from Surya Loan</h2>
              <ol>
                <li><strong>Quick Relief During Emergencies:</strong> Provides fast solutions for medical bills, car repairs, or urgent home repairs.</li>
                <li><strong>Flexibility:</strong> Use funds for various needs such as medical procedures, unexpected expenses, or essential bills.</li>
                <li><strong>Easy Application Process:</strong> Minimal documentation and online applications that take just minutes.</li>
                <li><strong>Short-Term Commitment:</strong> Quick repayment ensures no long-term financial strain.</li>
                <li><strong>Easy to Access:</strong> Available to individuals with less-than-perfect credit.</li>
                <li><strong>Minimal Paperwork:</strong> Requires very few documents, making the application process smooth and fast.</li>
                <li><strong>No Collateral Required:</strong> Loans are unsecured, eliminating the risk of losing valuable assets.</li>
              </ol>

              <h2>Documents Required for Short-Term Personal Loans</h2>
              <ul>
                <li>Latest 3-month salary slip & latest 6-month bank statement</li>
                <li>Aadhaar card (front and back) and PAN card</li>
                <li>Electricity bill (if own house or rented) and rent agreement</li>
                <li>Wi-Fi bill, credit bill, gas bill, water bill (any one)</li>
                <li>Two references with name and relationship</li>
                <li>Office address and mail ID with landmark (work from home or office)</li>
                <li>WhatsApp current location and an alternate mobile number</li>
              </ul>

              <h2>Eligibility Criteria for Short-Term Loan</h2>
              <p>Surya Loan offers an affordable small personal loan with easy-to-comply eligibility criteria.</p>
              <ul>
                <li><strong>Age:</strong> 21 to 58 years</li>
                <li><strong>Employment:</strong> Salaried person</li>
                <li><strong>Minimal Income:</strong> More than ₹25,000</li>
                <li><strong>Credit Score:</strong> 500+</li>
                <li><strong>Nationality:</strong> Indian</li>
              </ul>

              <h2>Conclusion</h2>
              <p>Surya Loan offers a straightforward solution for those seeking short-term personal loans. We provide quick access to funds, allowing you to tackle emergencies without delay. With competitive interest rates, flexible repayment options, and a simplified application process, borrowers can quickly access the funds they need. Our commitment to customer support ensures a smooth experience from application to repayment.</p>
              <div className="button_container flex justify-center">
                <Link to="/apply-now">Apply Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};

export default ShortLoan;
