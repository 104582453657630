import React from "react";
import "../css/ContentPage.css";
import ChatButton from "../components/ChatButton";
import { Link} from "react-router-dom";


const FAQ = () => {
  return (
    <>

      <div className="content_page_wrapper">
        <div className="content_page_banner_wrapper">
          <div className="content_page_banner_wrapper_overlay">
            <h2>FAQ</h2>
            <div style={{ marginTop: "10px" }}>
            <Link 
              to="/" 
              style={{ 
                color: "#26b9db", 
                fontWeight: "600", 
                marginTop: "10px", 
                textDecoration:"none" 
              }}
            >
              Home
            </Link>
            <span style={{ color: "white", fontSize: "16px", margin: "0 10px" }}>→</span>
              <span style={{ color: "white", fontWeight: "600", fontSize: "16px" }}>
                FAQ
              </span>
          </div>
        </div>
        </div>

        <div className="text_content_wrapper">
          <div className="text_content" style={{ width: "95%" }}>
            <h1 className="page_title mt30 mb50">Frequently asked questions</h1>
            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">What is SuryaLoan ? </h3>
                <p>
                SuryaLoan offers upto ₹1,00,000 loan amount to individuals, and Loan amounts also depending on factors such as your income, credit history, and local regulations.
                </p>
              </div>
              <div className="content_item">
                <h3>How much can I borrow from SuryaLoan?</h3>
                <p>
                  Loan amounts vary depending on factors such as your income,
                  credit history, and local regulations. SuryaLoan offers
                  flexible loan amounts tailored to individual needs, with
                  limits set to ensure responsible borrowing.
                </p>
              </div>
            </div>
            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">How does SuryaLoan work?</h3>
                <p>
                  SuryaLoan offers short-term loans to eligible individuals
                  based on their income and employment status. Customers can
                  apply online or through our mobile app, and if approved, funds
                  are typically disbursed within a short period.
                </p>
              </div>
              <div className="content_item">
                <h3>What are the repayment terms for SuryaLoan loans?</h3>
                <p>
                Repayment terms for SuryaLoan (personal loans providers) are depending on the loan amount. Generally, loans are repaid on due date at one time.
                </p>
              </div>
            </div>

            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">
                  What are the eligibility requirements to apply for a loan with SuryaLoan?
                </h3>
                <p>
                To apply for a loan with SuryaLoan, you normally need to show proof of a job, a stable income, and reach certain credit criteria. The exact requirements might pass based on the loan type and your local regulations.
                </p>
              </div>
              <div className="content_item">
                <h3>Are there any fees associated with SuryaLoan loans?</h3>
                <p>
                Yes, SuryaLoan charges fees for its services, including 10% processing fees of sanctioned amount, 18% GST, and loan interest charges.
                </p>
              </div>
            </div>
            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">
                  How quickly can I get funds from SuryaLoan?
                </h3>
                <p>
                Firstly visit the website, then apply for loan, and Once your online loan application is approved, funds are normally deposited into your account within one business day.
                </p>
              </div>
              <div className="content_item">
                <h3>Can I repay my SuryaLoan loan early?</h3>
                <p>
                Yes, borrowers can often repay their loan amount early.
                </p>
              </div>
            </div>
            <div className="content_row">
              <div className="content_item">
                <h3 className="mb10">
                  What happens if I can't repay my SuryaLoan loan on time?
                </h3>
                <p>
                If you're not able to repay your loan amount on the due date, it's required to contact the SuryaLoan Team immediately to discuss your solution. Then, SuryaLoan may offer repayment arrangements to help your loan amount.
                </p>
              </div>
              <div className="content_item">
                <h3>Is my personal information safe with SuryaLoan?</h3>
                <p>
                Yes, SuryaLoan, a personal loan company takes the privacy and security of customer information seriously.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};

export default FAQ;
