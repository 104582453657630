import React from "react";
import "../css/ContentPage.css";
import instant_loan from "../images/instanloan.png";
import { Link } from "react-router-dom";
import ChatButton from "../components/ChatButton";
import { email } from "../constants";


const InstantLoan = () => {
  return (
    <>
      <div className="content_page_wrapper">
        <div className="content_page_banner_wrapper">
          <div className="service_page_banner_wrapper_overlay">
            <h2>Instant Loans</h2>
          </div>

          <div className="text_content_wrapper">
            <div className="text_content">
              <div className="content_banner">
                <img src={instant_loan} alt="" />
              </div>
              <div>
                <h2 className="page_title mt30 mb20">Instant Personal Loan</h2>

                <h3>Understanding Instant Personal Loan</h3>

                <h4>What are instant personal loans?</h4>
                <p>Instant personal loan/instant loan is a form of loan which is taken from an instant loan provider/financial institution for personal uses such as family vacations, medical needs, consumer electronics and appliances, debt consolidation, credit card bill consolidation, wedding or any other such needs.</p>

                <h3>Eligibility Requirements For Instant Personal Loan</h3>

                <h4>Age Requirements:</h4>
                <ul>
                  <li><strong>Minimum Age:</strong> 21 years</li>
                  <li><strong>Maximum Age:</strong> 50 years at loan maturity</li>
                </ul>

                <h4>Documents for applying for the Instant Personal Loan:</h4>
                <ul>
                  <li>PAN copy, government-recognized address proof, identity proof, and bank statements.</li>
                  <li>Recent passport-sized photographs</li>
                  <li>There is absolutely no requirement of a good civil score.</li>
                  <li>A salary slip of the past three months.</li>
                  <li>You may also need to provide other documents on the basis of your personal or professional profile.</li>
                </ul>

                <p>Let’s have a look at some of the eligibility criteria for getting an instant personal loan:</p>
                <ul>
                  <li>The applicant must be a residing citizen of India.</li>
                  <li>If the applicant is a salaried individual, their minimum work experience must be at least 1 year considering 6 months completed with the present employer.</li>
                </ul>

                <h4>Salary Criteria for an Instant Personal Loan:</h4>
                <p>Monthly salary must be at least Rs. 26,000 when applying for the loan.</p>

                <h3>What Is The Procedure To Apply For an Instant Personal Loan?</h3>

                <p>For getting an easy personal loan, you can apply on the website. You can also connect with us via telephone on our toll-free number. Our representative will guide you about the loan.</p>

                <p>Before you apply for the loan, it is recommended to keep a soft-copy of the required documents handy so that you can upload them easily.</p>

                <p>Once you submit the application, our team will review the request and connect with you in a short while for the personal loan application status.</p>

                <p><strong>Surya Loan</strong> is the best preference for getting an instant personal loan. We are a technology-driven NBFC and ensure seamless service delivery.</p>

                <p>As we continue to evolve and expand our service offerings, our focus remains steadfast on building long-term relationships based on trust, transparency, and mutual growth.</p>

                <h3>So, if you want an instant personal loan:</h3>

                <p><strong>Call us on:</strong> 7404541855</p>
                <p><strong>Or send us an e-mail at:</strong> <a href="mailto:hr@suryaloan.com">{email}</a></p>
              </div>
              <div className="button_container flex justify-center">
                <Link to="/apply-now">Apply Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};

export default InstantLoan;
