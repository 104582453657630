import React from "react";
import "../css/ContentPage.css";
import service_three from "../images/flexi_loan.jpg";
import { Link } from "react-router-dom";
import ChatButton from "../components/ChatButton";


const ShortLoan = () => {
  return (
    <>
      <div className="content_page_wrapper">
        <div className="content_page_banner_wrapper">
          <div className="service_page_banner_wrapper_overlay">
            <h2>Flexibility</h2>
          </div>

          <div className="text_content_wrapper">
            <div className="text_content">
              <div className="content_banner">
                <img src={service_three} alt="" />
              </div>
              <div>
                <h2 className="page_title mt30 mb20">Flexibility of Loans in Surya Loan</h2>

                <p>Surya Loan, powered by Raghavi Finance Pvt. Ltd, is a personal loan company that offers different types of solutions with the ability to meet the needs of individual or company employees. Surya Loans include:</p>

                <h3>Main Features of Surya Loans:</h3>

                <ul>
                  <li><strong>Competitive Rates of Interest -</strong> Surya Loan provides competitive interest rates that vary according to the information on the borrower, customizing each loan to fit the financial situation of individuals.</li>
                  <li><strong>Simple Application Process -</strong> The application process is simple and in a way, simplified from providing too much information. Borrowers can apply through online forms or seek assistance at a local branch for the same.</li>
                  <li><strong>Fast Approval and Loan Disbursement -</strong> The application is sanctioned very quickly and funds are disbursed as soon as an application is filled and responds successfully.</li>
                  <li><strong>Flexible Repayment Options -</strong> Standard loan tenures allows borrowers to have ample options to repay the loan per their payment capacity.</li>
                  <li><strong>Unsecured Personal Loan -</strong> A loan is granted without needing collateral, therefore easing the availability of loans for individuals with fewer assets. This feature minimizes the barriers to getting loans for a larger number of people.</li>
                  <li><strong>Flexible loan amount -</strong> Surya Loans come with a flexible loan amount limit that can provide individuals with anything between ₹10000 lakh to ₹1 lakh against emergencies, vacations, or any personal projects.</li>
                </ul>

                <h3>Eligibility Criteria</h3>

                <p>Eligibility are basically required by any personal loan from Surya Loans where:</p>
                <ul>
                  <li>The minimum age should be 21.</li>
                  <li><strong>Income -</strong> Proof of sustainable income may be demanded.</li>
                </ul>

                <h3>Application Process</h3>

                <p>This application process is unique and user-friendly; to apply for a Surya Loan:</p>

                <ol>
                  <li><strong>Click on the “Apply Now” button -</strong> For the application procedure, visit the Surya Loan official website and click on the “Apply Now” button which will direct you to the application form where you are required to fill in your data.</li>
                  <li><strong>Phone Number -</strong> Write down your mobile phone number. This number will serve as the method of communication concerning your loan application, as well as for verification.</li>
                  <li><strong>OTP (One-Time Password) -</strong> You will receive an OTP via an SMS after entering your phone number. Type that OTP in the designated space to verify your phone number. This ensures that the provided contact details are valid and belong to you.</li>
                  <li><strong>PAN Number -</strong> Enter your Permanent Account Number (PAN). This is an important identification number used in India for taxation and also used for your identity and financial credibility.</li>
                  <li><strong>Personal Information -</strong> Complete the personal information that includes:</li>
                  <ul>
                    <li>Full name</li>
                    <li>Date of birth</li>
                    <li>Address</li>
                    <li>Email ID</li>
                  </ul>
                  <p>This information is necessary for drafting your profile and determining your eligibility for a loan.</p>
                  <li><strong>Income Details -</strong> You will need to provide the following income-related information:</li>
                  <ul>
                    <li>Monthly or annual salary</li>
                    <li>Source of income</li>
                    <li>Job details</li>
                  </ul>
                  <p>This helps the lender evaluate your repayment capacity.</p>
                  <li><strong>Selfie Upload -</strong> Upload a selfie. Most of the time, this is needed for KYC (Know Your Customer) verification.</li>
                </ol>

                <p>After completing these steps, you will have successfully applied for a Surya Loan and taken one decisive approach toward your financial needs.</p>

                <h3>Conclusion</h3>

                <p>With flexible terms, relatively low-interest rates, and an easy application process, Surya Loan is a personal loan company. From unplanned expenses to purchases you have been waiting for, with Surya Loan, you get customized services exactly meeting your needs, without the concern of collateral requirements.</p>
              </div>
              <div className="button_container flex justify-center">
                <Link to="/apply-now">Apply Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};

export default ShortLoan;
