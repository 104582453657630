import React from "react";
import { Link } from "react-router-dom";
import service_six from "../images/cost effective.webp";
import ChatButton from "../components/ChatButton";


const CostEffective = () => {
  return (
    <>

      <div className="content_page_wrapper">
        <div className="content_page_banner_wrapper">
          <div className="service_page_banner_wrapper_overlay">
            <h2>Cost Effective</h2>
          </div>

          <div className="text_content_wrapper">
            <div className="text_content">
              <div className="content_banner">
                <img src={service_six} alt="" />
              </div>
              <h1 className="page_title mt30 mb20">Surya Loan: Your Pathway to Cost-Effective Personal Loans</h1>

              <h2>Unlock Affordable Personal Loan Solutions with Surya Loan</h2>

              <p>In today's constantly-changing financial era, finding a cost-effective loan service can often feel like searching for a needle in a haystack. Luckily, <strong>Surya Loan</strong> is here to make your financial journey smoother with its finest loan services and excellent loan disbursement schemes that are set to fit your finances.</p>

              <h2>How Surya Loan Offers Cost-Effective Personal Loan Services</h2>

              <ul>
                <li><strong>Surya Loan</strong> stands out in the crowded financial world, offering a range of loans that are not only affordable but also designed to suit a variety of financial circumstances.</li>

                <li>From competitive interest rates to transparent and clear fee structures, we can assure you of the best value for your money. We help you reach your goal efficiently by providing loans with lower overall costs.</li>

                <li>Our streamlined application process reduces unnecessary expenditures and saves you money.</li>

                <li>The personal loans we offer introduce you to financial flexibility when you need it, whether for home upgrading, medical expenses, or perhaps a vacation of your dreams.</li>

                <li>At <strong>Surya Loan</strong>, we don't just finance—we offer solutions. Our financial experts are always on hand to advise customers, ensuring the best possible decision-making choices.</li>
              </ul>

              <h2>Conclusion</h2>

              <p>Choosing <strong>Surya Loan</strong> means choosing a trusted financial partner for your financial journey. We believe that financial support should be accessible and affordable, and we continually strive to provide loan services that offer incredible value and long-lasting savings.</p>
              <div className="button_container flex justify-center">
                <Link to="/apply-now">Apply Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};

export default CostEffective;
