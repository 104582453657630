import React, { useState } from "react";
import "../../css/Common.css";
import { Link } from "react-router-dom";
import { FaAngleDown, FaAngleUp, FaChevronRight } from "react-icons/fa";
import { address, email, phone } from "../../constants";
import logo from "../../images/nav_logo.png";

const Footer = () => {
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  return (
    <>
      <div className="footer_wrapper full-width">
        <div className="footer_row">
        <div className="footer_tab">
            <img src={logo}/>
          </div>
          <div className="footer_tab">
            <h2 className="tab_title">Quick Links</h2>
            <li>
              <Link to="/home">
                <FaChevronRight className="footer_icon" />
                Home
              </Link>
            </li>
            <li>
              <Link to="/apply-now">
                <FaChevronRight className="footer_icon" />
                Apply for Loan
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <FaChevronRight className="footer_icon" />
                Contact
              </Link>
            </li>
            <li>
              <Link to="/services">
                <FaChevronRight className="footer_icon" />
                Services
              </Link>
            </li>
          </div>
          <div className="footer_tab">
            <h2 className="tab_title">Legal</h2>
            <li>
              <Link to="/faq">
                <FaChevronRight className="footer_icon" />
                Faq's
              </Link>
            </li>
            <li>
              <Link to="/rateandterms">
                <FaChevronRight className="footer_icon" />
                Rate and Terms
              </Link>
            </li>
            <li>
              <Link to="/privacypolicy">
                <FaChevronRight className="footer_icon" />
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/termsandconditions">
                <FaChevronRight className="footer_icon" />
                Terms and Conditions
              </Link>
            </li>
          </div>
          <div className="footer_tab">
            <h2 className="tab_title">Contact Us</h2>
            <p className="call_info m-0">
              Email: {email}
            </p>
            <p className="call_info m-0">Phone: {phone}</p>
            <p className="call_info m-0">
              {address}
            </p>
          </div>
        </div>
        <div className="footer_dropdown_container">
          <div className="footer_dropdown">
            <div className="dropdown_item_title">
              <p
                className={
                  dropdown1
                    ? "dropdown_title flex flex-center dropdown_active"
                    : "dropdown_title flex flex-center"
                }
                onClick={(e) => setDropdown1((prev) => !prev)}
              >
                Loans for Lifestyle{" "}
                {dropdown1 ? (
                  <FaAngleUp className="dropdown_icon" />
                ) : (
                  <FaAngleDown className="dropdown_icon" />
                )}
              </p>
              <div
                className={
                  dropdown1 ? "block submenu dropdown_active md_none" : "hidden"
                }
              >
                <ul className="submenu_list">
                  <li>
                    <Link to="/flexibility">Flexible Personal Loan</Link>
                  </li>
                  <li>
                    <Link to="/short-term-loan">Short Term Loan</Link>
                  </li>
                  <li>
                    <Link to="/instant-loan">Instant Loan</Link>
                  </li>
                  <li>
                    <Link to="/debt-consolidation-loan">Debt Consolidation</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dropdown_item_title">
              <p
                className={
                  dropdown2
                    ? "dropdown_title flex flex-center dropdown_active"
                    : "dropdown_title flex flex-center"
                }
                onClick={(e) => setDropdown2((prev) => !prev)}
              >
                Loans in your City
                {dropdown2 ? (
                  <FaAngleUp className="dropdown_icon" />
                ) : (
                  <FaAngleDown className="dropdown_icon" />
                )}
              </p>

              <div
                className={
                  dropdown2 ? "flex submenu dropdown_active md_none" : "hidden"
                }
              >
                <ul className="submenu_list">
                {/* <li>
                  <Link to="/instant-personal-loan-in-ahmedabad"> Ahmedabad</Link>
                </li> */}
                <li>
                  <Link to="/instant-personal-loan-online-in-hyderabad"> Hyderabad</Link>
                </li>
                <li>
                  <Link to="/loan-agency-in-mumbai">Mumbai</Link>
                </li>
                <li>
                  <Link to="/advance-loans-Online-in-Bangalore">Bangalore</Link>
                </li>
                <li>
                  <Link to="/Instant-Personal-Loan-for-Salaried-in-Delhi">New Delhi</Link>
                </li>
                <li>
                  <Link to="/personal-loan-in-Gurgaon">Gurgaon</Link>
                </li>
                <li>
                  <Link to="/Personal-loan-in-Noida">Noida</Link>
                </li>
                <li>
                  <Link to="/personal-loan-in-faridabad">Faridabad</Link>
                </li>
                {/* <li>
                  <Link to="/instant-personal-loan-in-greater-noida">Greater Noida</Link>
                </li> */}
                <li>
                  <Link to="/Instant-Personal-Loan-in-Ghaziabad">Ghaziabad</Link>
                </li>
              </ul>
              </div>
            </div>
            <div className="dropdown_item_title">
              <p
                className={
                  dropdown3
                    ? "dropdown_title flex flex-center dropdown_active"
                    : "dropdown_title flex flex-center"
                }
                onClick={(e) => setDropdown3((prev) => !prev)}
              >
                Loans by Ticket Size
                {dropdown3 ? (
                  <FaAngleUp className="dropdown_icon" />
                ) : (
                  <FaAngleDown className="dropdown_icon" />
                )}
              </p>

              <div
              className={dropdown3 ? "flex submenu dropdown_active md_none" : "hidden"}
            >
              <ul className="submenu_list">
                <li>
                  <Link>5k - 1Lakh</Link>
                </li>
              </ul>
            </div>
            </div>
          </div>
          <div className="x_sm_none">
            <div
              className={dropdown1 ? "block submenu dropdown_active" : "hidden"}
            >
              <ul className="submenu_list">
                <li>
                  <Link to="/flexibility">Flexible Personal Loan</Link>
                </li>
                <li>
                  <Link to="/short-term-loan">Short Term Loan</Link>
                </li>
                <li>
                  <Link to="/instant-loan">Instant Loan</Link>
                </li>
                <li>
                  <Link to="/debt-consolidation-loan">Debt Consolidation</Link>
                </li>
              </ul>
            </div>
            <div
              className={dropdown2 ? "flex submenu dropdown_active" : "hidden"}
            >
              <ul className="submenu_list">
                {/* <li>
                  <Link to="/instant-personal-loan-in-ahmedabad"> Ahmedabad</Link>
                </li> */}
                <li>
                  <Link to="/instant-personal-loan-online-in-hyderabad"> Hyderabad</Link>
                </li>
                <li>
                  <Link to="/loan-agency-in-mumbai">Mumbai</Link>
                </li>
                <li>
                  <Link to="/advance-loans-Online-in-Bangalore">Bangalore</Link>
                </li>
                <li>
                  <Link to="/Instant-Personal-Loan-for-Salaried-in-Delhi">New Delhi</Link>
                </li>
                <li>
                  <Link to="/personal-loan-in-Gurgaon">Gurgaon</Link>
                </li>
                <li>
                  <Link to="/Personal-loan-in-Noida">Noida</Link>
                </li>
                <li>
                  <Link to="/personal-loan-in-faridabad">Faridabad</Link>
                </li>
                {/* <li>
                  <Link to="/instant-personal-loan-in-greater-noida">Greater Noida</Link>
                </li> */}
                <li>
                  <Link to="/Instant-Personal-Loan-in-Ghaziabad">Ghaziabad</Link>
                </li>
              </ul>
            </div>
            <div
              className={dropdown3 ? "flex submenu dropdown_active" : "hidden"}
            >
              <ul className="submenu_list">
                <li>
                  <Link>5k - 1Lakh</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright_section mt30">
          <p>
            Copyright ©  Raghavi Finance Limited. All Rights Reserved.
          </p>
        </div>
        <div className="text-center pb-3">
          <div>
            <a href="https://www.facebook.com/easyloanbysuryaloan" target="_blank"> Facebook </a>  | 
            {/* <a href="#"> Twitter </a>  |  */}
            <a href="https://www.instagram.com/suryaloan?igsh=MWo2Z2FzNDY1NWpwZw==" target="_blank"> Instagram </a>  | 
            <a href="https://www.linkedin.com/company/suryaloan/" target="_blank"> Linkedin </a> 
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
